body {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  .loader-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    z-index: 150;
  }

  .loader {
    border: 16px solid #e3e3e3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 140px;
    height: 140px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }