.ReactPaginate {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-top: 20px;
    cursor: pointer;
    padding-left: 0;
}
.ReactPaginate  li {
    padding: 5px 10px;
    border-radius: 4px;
}
.ReactPaginate a {
    padding: 10px;
    /*border-radius: 5px;*/
    /*border: 1px solid #4C494F;*/
    color: #4C494F;
}
.item {
    font-weight: 500;
}
.item:hover {
    color: #90A955;
}

.active {

    color: #000;
    /* background-color: #90A955; */
}
/*.active:hover {*/
/*     color: #4C494F;*/
/* }*/
/*.disabled {*/
/*     color: rgb(198, 197, 202);*/
/* }*/